import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  useDataProvider,
  useListContext,
  useRefresh,
  useUnselect,
} from 'react-admin'
import { t } from 'i18next'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Switch, ToggleButton } from '@mui/material'
import Dialog from '../../components/Dialog'
import { getLabelSuffix } from '../../helpers'
import { Button } from '../../components/common/Button'
import { TagCategory } from '../../components/customCharts/CustomChartState'
import TransactionListBulkEditContent from './TransactionListBulkEditContent'
import LinearProgress from '../../components/Loaders/LinearProgress'
import { SelectedTags } from './TransactionListBulkEdit.types'
import { Transaction } from '../TransactionList.types'
import { bulkUpdate } from './TransactionListBulkEdit.utils'

const areAllTagSelectionsPopulated = (
  selectedTags: SelectedTags,
  isDeleting: boolean
) =>
  selectedTags.every((selection) =>
    isDeleting ? !!selection[0] : !!selection[0] && !!selection[1]
  )

const DEFAULT_SELECTED_TAGS: [null, null][] = [[null, null]]

const TransactionListBulkEdit = ({
  open,
  onClose,
  initialQuery,
}: {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  initialQuery
}) => {
  const dp = useDataProvider()
  const refresh = useRefresh()
  const list = useListContext<Transaction>()
  const unselect = useUnselect(list.resource)
  const suffix = getLabelSuffix()
  const [tagCategories, setTagCategories] = useState<TagCategory[]>([])
  const [selectedTags, setSelectedTags] = useState<SelectedTags>(
    DEFAULT_SELECTED_TAGS
  )
  const [submitting, setSubmitting] = useState(false)
  const [pageProgress, setPageProgress] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [error, setError] = useState(false)

  const fetchTags = useCallback(async () => {
    setSelectedTags([[null, null]])
    const tagCs = await dp.getTagCategories()
    setTagCategories(tagCs)
  }, [dp])

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  const updateAllTransactions = async () => {
    setSubmitting(true)
    try {
      await bulkUpdate(
        selectedTags,
        list,
        initialQuery,
        dp,
        setPageProgress,
        isDeleting
      )
      unselect(list.selectedIds)
      refresh()
      setSubmitting(false)
      onClose(false)
    } catch (e) {
      setError(true)
    }
  }

  const numberOfTransactions = list.selectedIds?.length
    ? list.selectedIds.length
    : list.total

  const percentageProgress = ~~Math.min(
    ((pageProgress * list.perPage) / numberOfTransactions) * 100,
    100
  )

  const handleErrorOk = () => {
    refresh()
    setError(false)
    setSubmitting(false)
    onClose(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': { height: '50%' },
        }}
      >
        <div className="flex justify-between">
          <DialogTitle>{t(`bulk_edit_transactions${suffix}`)}</DialogTitle>
          <div className="px-4 flex items-center">
            <span className={!isDeleting ? 'font-bold text-main' : ''}>
              Edit
            </span>
            <Switch
              checked={isDeleting}
              onChange={({ target: { checked } }) => setIsDeleting(checked)}
            />
            <span className={isDeleting ? 'font-bold text-main' : ''}>
              Delete
            </span>
          </div>
        </div>
        <TransactionListBulkEditContent
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          tagCategories={tagCategories}
          totalTransactionNumber={numberOfTransactions}
          isDeleting={isDeleting}
          hasTransactionsSelected={!!list.selectedIds.length}
        />
        <DialogActions>
          <Button secondary onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            disabled={!areAllTagSelectionsPopulated(selectedTags, isDeleting)}
            onClick={updateAllTransactions}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={submitting}>
        <DialogContent sx={{ width: '400px' }}>
          <div>
            Updating {numberOfTransactions} transaction
            {numberOfTransactions > 1 ? 's' : ''}...
          </div>
          <div style={{ margin: '12px 0 36px' }}>
            <LinearProgress value={percentageProgress} />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={error}>
        <DialogContent sx={{ width: '400px' }}>
          <div>An error has occurred</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TransactionListBulkEdit
