import { DataProvider, ListControllerResult } from 'ra-core'
import { Dispatch, SetStateAction } from 'react'
import { BulkEditPost, SelectedTags } from './TransactionListBulkEdit.types'
import {
  Transaction,
  TransactionListInitialQuery,
} from '../TransactionList.types'
import { apiUrl, httpClient } from '../../dataProvider'

// creates the edit payload for the current page
const createPagePayload = (
  transactions: Transaction[],
  tagUpdateIds: number[][],
  isDeleting: boolean
): BulkEditPost => {
  return {
    transactions: transactions.map((transaction) => {
      const transactionTagIdsToEdit = isDeleting
        ? transaction.tags
            .filter(
              (tag) =>
                tagUpdateIds.findIndex(
                  (tagToUpdate) => tagToUpdate[0] === tag.tag_category.id
                ) !== -1
            )
            .map((tagToUpdate) => tagToUpdate.id)
        : tagUpdateIds.map((tagToUpdate) => tagToUpdate[1])
      return {
        transaction_id: transaction.id,
        transaction: {
          invoice_date: transaction.invoice_date,
          extra: null,
          amount: transaction.amount,
          invoice_vendor_name: transaction.invoice_vendor_name,
          matches_length: 0,
          vendor_match_flags: 0,
          txn_id: transaction.id.toString(),
          guid: '',
          possible_duplicate: false,
          vendor_id: transaction.vendor.id,
          tag_ids: transactionTagIdsToEdit,
        },
      }
    }),
  }
}

export const bulkUpdate = async (
  tagUpdates: SelectedTags,
  list: ListControllerResult<Transaction>,
  initialQuery: TransactionListInitialQuery,
  dp: DataProvider,
  setPageProgress: Dispatch<SetStateAction<number>>,
  isDeleting: boolean
) => {
  const tagIdsToUpdate = tagUpdates
    .filter(([tagCat, tag]) =>
      isDeleting ? !!tagCat?.id : !!tagCat?.id && !!tag?.id
    )
    .map(([tagCat, tag]) => [tagCat?.id as number, tag?.id as number])

  const totalPages = list.selectedIds?.length
    ? 1
    : Math.ceil(list.total / list.perPage)

  if (list.selectedIds?.length) {
    const transactions = list.data.filter((transaction) =>
      list.selectedIds.includes(transaction.id)
    )
    const pagePayload = createPagePayload(
      transactions,
      tagIdsToUpdate,
      isDeleting
    )
    await httpClient(`${apiUrl}/transactions/bulkEdit`, {
      method: isDeleting ? 'DELETE' : 'POST',
      body: JSON.stringify(pagePayload),
    })
    setPageProgress(1)
  } else {
    for (let page = 1; page <= totalPages; page += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const { data: pageTransactions } = await dp.getList<Transaction>(
          'transactions',
          {
            filter: initialQuery,
            pagination: { page, perPage: list.perPage },
            sort: { field: list.sort.field, order: list.sort.order },
          }
        )

        const pagePayload = createPagePayload(
          pageTransactions,
          tagIdsToUpdate,
          isDeleting
        )

        // eslint-disable-next-line no-await-in-loop
        await httpClient(`${apiUrl}/transactions/bulkEdit`, {
          method: isDeleting ? 'DELETE' : 'POST',
          body: JSON.stringify(pagePayload),
        })

        setPageProgress(page)
      } catch (e) {
        console.error('Failed to update page:', page)
        console.error(e)
      }
    }
  }
}
