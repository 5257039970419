import {
  ArrayField,
  BooleanInput,
  BulkDeleteButton,
  BulkExportButton,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  List,
  SelectInput,
  SingleFieldList,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  usePermissions,
  useUnselect,
} from 'react-admin'

import { Box } from '@mui/material'
import { FolderArrowDownIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import AmountField from '../components/AmountField'
import { Title } from '../components/Title'
import MigrateButton from './MigrateButton'
import VendorMatchFlags from './VendorMatchFlags'
import ReferenceVendorMapper from './ReferenceVendorMapper'
import AssignGroupButton from './AssignGroupButton'
import userPermissions from '../Users/userPermissions'
import { getLabelSuffix } from '../helpers'
import { DateRangeFilterContext } from '../contexts/DateRangeFilterContext'
import { useQueryFilter } from '../helpers/useQueryFilter'
import { Button } from '../components/common/Button'
import TransactionListBulkEdit from './TransactionListBulkEdit'
import { TransactionListInitialQuery } from './TransactionList.types'

export const ops = [
  { name: 'in', label: 'in' },
  { name: 'notin', label: 'not in' },
  // { name: '=', label: 'is' },
  // { name: '!=', label: 'is not' },
]

export const vendorOps = [
  { name: 'in', label: 'in' },
  { name: 'notin', label: 'not in' },
]

const ExportCsvButton = () => {
  const { filterValues } = useListContext()

  const dp = useDataProvider()

  const downloadFile = (token) => {
    dp.getCSVUrl(filterValues, token).then((url) => {
      window.location = url
    })
  }

  const handleDownload = () => {
    dp.getCSVRowCount(filterValues).then((data) => {
      // magic number for rows
      const confirmLimit = 1048576
      if (data.data.count >= confirmLimit) {
        if (
          window.confirm(
            'This export will exceed 1,048,576 rows. Are you sure you wish to continue?'
          )
        ) {
          downloadFile(data.data.token)
        }
      } else {
        downloadFile(data.data.token)
      }
    })
  }

  return (
    <button
      type="button"
      className="py-2 border border-green-800 p-3 active rounded bg-green-700 text-white font-medium text-sm inline-flex space-x-2 items-center hover:bg-opacity-90"
      color="primary"
      onClick={handleDownload}
    >
      <FolderArrowDownIcon className="h-4" />
      <span>Download to Excel</span>
    </button>
  )
}

const BulkActionList = () => {
  const { permissions } = usePermissions()
  const { selectedIds } = useListContext()

  if (selectedIds?.length < 1) return null
  if (!(permissions & userPermissions.EDIT_TRANSACTIONS)) return null

  return (
    <div className="relative">
      <div>
        <div className="bg-gray-100 text-xs font-medium py-1.5 px-3 rounded-t border border-b-0 inline-block">
          Bulk edit ({selectedIds.length} Selected)
        </div>
      </div>
      <div className="flex items-center gap-2.5 fade-in-up py-1 px-2 rounded-md rounded-tl-none border bg-white">
        <BulkDeleteButton mutationMode="pessimistic" />
        <BulkExportButton />
        {/* <ConfirmVendorButton /> */}
      </div>
    </div>
  )
}

const ListActions = ({
  setOpenBulkEditModal,
}: {
  setOpenBulkEditModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { permissions } = usePermissions()
  const list = useListContext()
  const location = useLocation()
  const unselect = useUnselect(list.resource)

  useEffect(() => {
    unselect(list.selectedIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Box width="100%">
      <TopToolbar>
        <BulkActionList />
        {permissions & userPermissions.EDIT_TRANSACTIONS && (
          <Button
            disabled={!list.data?.length}
            onClick={() => setOpenBulkEditModal((prev) => !prev)}
          >
            Edit {list.selectedIds?.length ? 'Selected' : 'All'}
          </Button>
        )}
        <ExportCsvButton />
      </TopToolbar>
    </Box>
  )
}

const TransactionList = () => {
  const [openBulkEditModal, setOpenBulkEditModal] = useState(false)
  const { permissions } = usePermissions()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const dateRangeFilter = useContext(DateRangeFilterContext)
  const { currentQuery } = useQueryFilter()

  const TransactionFilters = [
    <SelectInput
      sx={{ minWidth: 300 }}
      label={t(`vendor_match_flags${suffix}`)}
      source="vendor_match_flags"
      choices={VendorMatchFlags}
      emptyText="Show all"
      alwaysOn
    />,
    <BooleanInput
      label={t(`uncategorised_transactions${suffix}`)}
      source="untagged"
      alwaysOn
      parse={Boolean}
    />,
  ]

  const initialQuery: TransactionListInitialQuery = {
    startDate: dateRangeFilter.ranges?.[0]?.startDate,
    endDate: dateRangeFilter.ranges?.[0]?.endDate,
    query: JSON.stringify(currentQuery),
  }

  return (
    <section className="space-y-6 w-full">
      <Title>{t(`transactions${suffix}`)}</Title>
      <List
        className="w-full"
        sort={{ field: 'invoice_date', order: 'DESC' }}
        actions={<ListActions setOpenBulkEditModal={setOpenBulkEditModal} />}
        filters={TransactionFilters}
        filter={initialQuery}
        sx={{ RaFilterForm: { alignSelf: 'center' } }}
      >
        <Datagrid className="w-full">
          <TextField source="txn_id" label="Tx Number" />
          <TextField source="invoice_vendor_name" label="Vendor" />
          <AmountField label="Amount" />
          <DateField source="invoice_date" />
          <ArrayField source="tags" sortable={false}>
            <SingleFieldList className="flex" linkType={false}>
              <ChipField
                source="name"
                className="!max-w-[400px] text-ellipsis"
              />
            </SingleFieldList>
          </ArrayField>
          {permissions & userPermissions.SUPER_ADMIN && (
            <ReferenceVendorMapper />
          )}
          {permissions & userPermissions.EDIT_TRANSACTIONS && (
            <AssignGroupButton />
          )}
          {permissions & userPermissions.EDIT_TRANSACTIONS && <MigrateButton />}
          {permissions & userPermissions.EDIT_TRANSACTIONS && (
            <EditButton label="" />
          )}
        </Datagrid>
        {openBulkEditModal && (
          <TransactionListBulkEdit
            open={openBulkEditModal}
            onClose={() => setOpenBulkEditModal(false)}
            initialQuery={initialQuery}
          />
        )}
      </List>
    </section>
  )
}

export default TransactionList
